<template>
  <div class="contact-wrapper confirm-page">
    <div class="page-header">
      <h2>{{ $t("contact.title_confirm_contact") }}</h2>
      <img class="header-img" src="@/assets/icons/multiple.svg" alt="" />
    </div>
    <div class="confirm-wrapper">
      <div class="confirm-text">
        {{ $t("contact.description_check_content_input") }}
      </div>
      <div class="selected-contact type">
        <div class="header">
          {{ $t('contact.label_name') }}
        </div>
        <div class="cross-line"></div>
        <div class="item">
          {{ contactForm.name }}
        </div>
      </div>

      <div class="selected-contact type">
        <div class="header">
          {{ $t('contact.label_company_name') }}
        </div>
        <div class="cross-line"></div>
        <div class="item">
          {{ contactForm.companyName }}
        </div>
      </div>

      <div class="selected-contact type">
        <div class="header">
          {{ $t('contact.label_telephone_number') }}
        </div>
        <div class="cross-line"></div>
        <div class="item">
          {{ contactForm.phone }}
        </div>
      </div>

      <div class="selected-contact type">
        <div class="header">
          {{ $t('contact.label_email_address') }}
        </div>
        <div class="cross-line"></div>
        <div class="item">
          {{ contactForm.email }}
        </div>
      </div>

      <div class="selected-contact content">
        <div class="header">
          {{ $t('contact.label_content_of_inquiry') }}
        </div>
        <div class="cross-line"></div>
        <div class="item">
          {{ contactForm.contactData }}
        </div>
      </div>
      <div class="confirm-btn-wrapper">
        <div class="back" @click="backToContactIndex()">
          <span>← {{ $t("contact.button_back_to_input_screen") }}</span>
        </div>
        <div class="next" @click="sendContactForm()">
          <span>{{ $t("contact.button_send") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { contactSupplierApi } from '@/api/supplier';

export default {
  props: {
    linkToPage: {
      type: String,
      default: 'index',
    },
    contactForm: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      isWaitingSend: true,
    }
  },
  computed: {
    // ...mapState('userData', ['contactForm']),

    getLinkToPage: {
      get() {
        return this.linkToPage;
      },
      set(value) {
        this.$emit('update:linkToPage', value);
      },
    }
  },
  data() {
    return {
      isWaitingSend: true,
    }
  },
  methods: {
    ...mapActions('userData', ['actionupdateContactForm']),

    backToContactIndex() {
      this.getLinkToPage = 'index';
      this.actionupdateContactForm({
        selectedContactType: this.contactForm.selectedContactType,
        isCheck: true,
        contactData: this.contactForm.contactData,
        isConfirmPage: false,
      })
    },
    async sendContactForm() { 
      this.getLinkToPage = 'success';
      const contactPayload = {
        name: this.contactForm.name,
        company_name: this.contactForm.companyName,
        phone : this.contactForm.phone,
        email : this.contactForm.email,
        content : this.contactForm.contactData
      }

      await contactSupplierApi(contactPayload).then(() => {
        // this.isWaitingSend = true;
        this.actionupdateContactForm({
          selectedContactType: '',
          isCheck: false,
          contactData: '',
          isConfirmPage: false,
        })
      })
    },
  }
}
</script>
<style lang="scss" scoped>
@import '@/views/contact/styles/index.scss';
</style>
